import React, { useState } from 'react';
import './header.css';
import dropDownIcon from '../../images/icons/drop-down.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { clearAccessData } from '../../store/authorization/actions';

const Header = () => {
    const dispatch = useDispatch();
    const autherizeState = useSelector((state: ApplicationState) => state.autherize);
    const [profileMenu, setProfileMenu] = useState({
        show: false,
        topOffset: 0,
        leftOffset: 0,
    });
    return (
        <>
            {autherizeState.data === null ?
                <></> :
                <div className='header-container'>
                    <div>
                        <p className='m-0 header-info-title'>{autherizeState.data.school_name}</p>
                        <p className='m-0 header-info-type'>Teacher</p>
                    </div>
                    <div className="d-flex"
                        onClick={(e) => {
                            const {
                                offsetTop,
                                offsetHeight,
                                offsetLeft,
                                offsetWidth,
                            } = e.currentTarget;
                            const topOffset = offsetTop + offsetHeight;
                            const leftOffset = offsetLeft + offsetWidth - 200 + 13;
                            setProfileMenu({
                                show: !profileMenu.show,
                                topOffset,
                                leftOffset,
                            });
                        }}
                    >
                        <div className=''>
                            <p className='m-0 header-info-title'>{autherizeState.data.fullname}</p>
                            {/* <p className='m-0 header-info-type'>English Teacher</p> */}
                        </div>
                        <img className="ml-2" src={dropDownIcon} alt="" />
                        <div className="card header-menu-profile position-absolute m-2"
                            style={{
                                top: `${profileMenu.topOffset}px`,
                                left: `${profileMenu.leftOffset}px`,
                                display: profileMenu.show ? 'unset' : 'none',
                            }}
                        >
                            <div className="header-menu-profile-border" />
                            <div className="header-menu-profile-text-container"
                                onClick={() => {
                                    dispatch(clearAccessData());
                                }}>
                                <p className="header-menu-profile-text">Log Out</p>
                            </div>
                        </div>
                        {profileMenu.show ? <div className="header-wrap"></div> : <></>}
                    </div>
                </div>
            }
        </>
    );
};

export default Header;
