import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest, deleteStudentRequest } from '../../store/approval/actions';
import { callApi } from '../../utils/api';
import { StudentInfo } from '../../store/approval/types';
import './table.css';
import './playlist-table.css';
import userImage from '../../images/user/user-image.png';



const UserTable = () => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [addId, setAddId] = useState<string>('');

    const sortedList = approvedList.data ? [...approvedList.data].sort((a, b) => a.fullname.localeCompare(b.fullname)) : [];



    const handleAdd = async (id: string) => {
        try {
            const { token } = loginState.data;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/school/user/add`, token, { userId: id });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleAddInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setAddId(event.target.value);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && addId.trim()) {
            handleAdd(addId.trim()); setAddId('');
        }
    };

    const handleRejectClick = (data: StudentInfo) => dispatch(deleteStudentRequest(data));



    useEffect(() => {
        dispatch(fetchApprovedListRequest());
    }, [dispatch]);



    return (
        <table className="table-container">
            <thead>
                <tr>
                    <th className="table-id">ID</th>
                    <th className="table-name">Name</th>
                    <th className="table-add">
                        <input
                            type="text" value={addId}
                            onChange={handleAddInputChange} onKeyDown={handleKeyDown}
                            placeholder="Enter User ID"
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedList.length > 0 ? (
                    sortedList.map((user) => (
                        <tr key={user.id}>
                            <td className="table-id">{user.id}</td>
                            <td>
                                {(user.lowPhotoUrl === '' || user.lowPhotoUrl === null) ?
                                    <img className='table-profile-image' src={userImage} alt='' /> :
                                    <img className='table-profile-image' src={user.lowPhotoUrl} alt='' />
                                }
                                {user.fullname}
                            </td>
                            <td className="table-add">
                                <button className="remove-button" onClick={() => handleRejectClick(user)}>Remove</button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={3} className="no-playlists">No users Available</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default UserTable;
