import {FilterState, FilterActionTypes} from './types';

const initialState: FilterState = {
  studentFilter: {
    grade: null,
    room: null,
  },
  videoFilter: {
    grade: null,
    subject: null,
    topic: null,
    chapter: null,
  },
  roomOptions: {
    loading: false,
    data: [],
    error: null,
  },
  topicOptions: {
    loading: false,
    data: [],
    error: null,
  },
  chapterOptions: {
    loading: false,
    data: [],
    error: null,
  },
};

const reducer = (state: FilterState = initialState, action: any) => {
  switch (action.type) {
    case FilterActionTypes.SET_GRADE_STUDENT_FILTER: {
      return {
        ...state,
        studentFilter: {
          grade: action.payload,
          room: state.studentFilter.room,
        }};
    }
    case FilterActionTypes.SET_ROOM_STUDENT_FILTER: {
      return {
        ...state,
        studentFilter: {
          grade: state.studentFilter.grade,
          room: action.payload,
        }};
    }
    case FilterActionTypes.SET_GRADE_VIDEO_FILTER: {
      return {
        ...state,
        videoFilter: {
          grade: action.payload,
          subject: state.videoFilter.subject,
          topic: null,
          chapter: null,
        }};
    }
    case FilterActionTypes.SET_SUBJECT_VIDEO_FILTER: {
      return {
        ...state,
        videoFilter: {
          grade: state.videoFilter.grade,
          subject: action.payload,
          topic: null,
          chapter: null,
        }};
    }
    case FilterActionTypes.SET_TOPIC_VIDEO_FILTER: {
      return {
        ...state,
        videoFilter: {
          grade: state.videoFilter.grade,
          subject: state.videoFilter.subject,
          topic: action.payload,
          chapter: null,
        }};
    }
    case FilterActionTypes.SET_CHAPTER_VIDEO_FILTER: {
      return {
        ...state,
        videoFilter: {
          grade: state.videoFilter.grade,
          subject: state.videoFilter.subject,
          topic: state.videoFilter.topic,
          chapter: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_ROOM_OPTIONS_REQUEST: {
      return {
        ...state,
        roomOptions: {
          loading: true,
        }};
    }
    case FilterActionTypes.FETCH_ROOM_OPTIONS_SUCCESS: {
      return {
        ...state,
        roomOptions: {
          loading: false,
          data: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_ROOM_OPTIONS_ERROR: {
      return {
        ...state,
        roomOptions: {
          loading: false,
          error: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_TOPIC_OPTIONS_REQUEST: {
      return {
        ...state,
        topicOptions: {
          loading: true,
        }};
    }
    case FilterActionTypes.FETCH_TOPIC_OPTIONS_SUCCESS: {
      return {
        ...state,
        topicOptions: {
          loading: false,
          data: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_TOPIC_OPTIONS_ERROR: {
      return {
        ...state,
        topicOptions: {
          loading: false,
          error: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_CHAPTER_OPTIONS_REQUEST: {
      return {
        ...state,
        chapterOptions: {
          loading: true,
        }};
    }
    case FilterActionTypes.FETCH_CHAPTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        chapterOptions: {
          loading: false,
          data: action.payload,
        }};
    }
    case FilterActionTypes.FETCH_CHAPTER_OPTIONS_ERROR: {
      return {
        ...state,
        chapterOptions: {
          loading: false,
          error: action.payload,
        }};
    }
    default: {
      return state;
    }
  }
};

export {reducer as filterReducer};
