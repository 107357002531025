import React from 'react';
import Login from '../../components/login/login';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';

const LoginPage = () => {
    return <>
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '50px 0px',
            }}>
                <img src={logoGrey}
                    srcSet={`${logoGrey2x} 2x,
              ${logoGrey3x} 3x`} alt="">
                </img>
            </div>
            <Login></Login>
        </div>
    </>;
};

export default LoginPage;
