import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { setToggle } from '../../store/toggle/actions';
import Header from '../../components/header/header';
import LoginPage from '../login';
import DashboardPage from '../dashboard';
import PlaylistPage from '../playlist';
import './index.css';



const Main = () => {
    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const toggleState = useSelector((state: ApplicationState) => state.toggle);
    const playlists = useSelector((state: ApplicationState) => state.toggle.playlists);

    if (!loginState.data) return <LoginPage />;

    const extractPlaylistName = (name: string) => {
        const match = name.match(/(?:.*\s)([^ \d][^]*)/);
        return match ? match[1] : name;
    };

    return (
        <>
            <Header />

            <div className="main-toggle-container">
                <button
                    onClick={() => dispatch(setToggle('dashboard'))}
                    className={`main-toggle-button mr-2 ${toggleState.toggle === 'dashboard' ? 'active' : ''}`}
                >
                    Dashboard
                </button>

                {playlists.map((playlist) => (
                    <button
                        key={playlist.id}
                        onClick={() => dispatch(setToggle(`playlist-${playlist.id}`))}
                        className={`main-toggle-button ${toggleState.toggle === `playlist-${playlist.id}` ? 'active' : ''}`}
                    >
                        {extractPlaylistName(playlist.name)} – {playlist.instructor}
                    </button>
                ))}
            </div>

            {toggleState.toggle === 'dashboard' && <DashboardPage />}
            {playlists.map((playlist) => (
                toggleState.toggle === `playlist-${playlist.id}` && <div key={playlist.id}><PlaylistPage /></div>
            ))}
        </>
    );
};

export default Main;
