import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { FilterActionTypes, Topic, Option, Chapter, Room } from './types';
import { fetchRoomOptionsSuccess, fetchTopicOptionsSuccess, fetchChapterOptionsSuccess } from './actions';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../index';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const getUserProfile = (state: ApplicationState) => state.autherize.data;
const getVideoFilter = (state: ApplicationState) => state.filter.videoFilter;
const getStudentFilter = (state: ApplicationState) => state.filter.studentFilter;



function* handleFetchRoomOptions(): SagaIterator {
    try {
        const userProfile = yield select(getUserProfile);
        if (!userProfile.school_id) return;

        const studentFilter = yield select(getStudentFilter);
        const grade = studentFilter.grade ? studentFilter.grade.value : '';

        const path = `/v1/school/${userProfile.school_id}/rooms?grade=${grade}`;
        const { error, data } = yield call(callApi, 'get', API_ENDPOINT + path, null);

        if (error) {
            console.error('Error fetching rooms:', error);
        } else {
            const rooms: Option[] = data.rooms.map((room: Room): Option => ({
                value: room.id, label: room.name, object: room,
            }));
            yield put(fetchRoomOptionsSuccess(rooms));
        }
    } catch (err) {
        console.error('An error occurred:', err);
    }
}



function* handleFetchTopicOptions(): SagaIterator {
    try {
        const { grade = '', subject = '' } = yield select(getVideoFilter);
        if (!grade || !subject) return;

        const path = `/v1/topic/list2?grade=${grade.value}&subject_id=${subject.value}`;
        const { error, data } = yield call(callApi, 'get', API_ENDPOINT + path, null);

        if (error) {
            console.error('Error fetching topics:', error);
        } else {
            const topics = data.topics.map((topic: Topic): Option => ({
                value: topic.topic_id,
                label: topic.description_th,
            }));
            yield put(fetchTopicOptionsSuccess(topics));
        }
    } catch (err) {
        console.error('An error occurred:', err);
    }
}



function* handleFetchChapterOptions(): SagaIterator {
    try {
        const { grade = '', subject = '', topic = '' } = yield select((state: ApplicationState) => state.filter.videoFilter || {});
        if (!grade || !subject || !topic) return;

        const path = `/v1/chapter/list2?grade=${grade.value}&subject_id=${subject.value}&topic_id=${topic.value}`;
        const { error, data } = yield call(callApi, 'get', API_ENDPOINT + path, null);

        if (error) {
            console.error('Error fetching chapters:', error);
        } else {
            const chapters: Option[] = data.chapters.map((chapter: Chapter): Option => ({
                value: chapter.chapter_id,
                label: chapter.description_th,
            }));
            yield put(fetchChapterOptionsSuccess(chapters));
        }
    } catch (err) {
        console.error('An error occurred:', err);
    }
}



function* watchFetchRoomOptionsRequest() {
    yield takeEvery(FilterActionTypes.FETCH_ROOM_OPTIONS_REQUEST, handleFetchRoomOptions);
    yield takeEvery(FilterActionTypes.SET_GRADE_STUDENT_FILTER, handleFetchRoomOptions);
}



function* watchFetchTopicOptionsRequest() {
    yield takeEvery(FilterActionTypes.FETCH_TOPIC_OPTIONS_REQUEST, handleFetchTopicOptions);
    yield takeEvery(FilterActionTypes.SET_GRADE_VIDEO_FILTER, handleFetchTopicOptions);
    yield takeEvery(FilterActionTypes.SET_SUBJECT_VIDEO_FILTER, handleFetchTopicOptions);
}



function* watchFetchChapterOptionsRequest() {
    yield takeEvery(FilterActionTypes.FETCH_CHAPTER_OPTIONS_REQUEST, handleFetchChapterOptions);
    yield takeEvery(FilterActionTypes.SET_GRADE_VIDEO_FILTER, handleFetchChapterOptions);
    yield takeEvery(FilterActionTypes.SET_SUBJECT_VIDEO_FILTER, handleFetchChapterOptions);
    yield takeEvery(FilterActionTypes.SET_TOPIC_VIDEO_FILTER, handleFetchChapterOptions);
}



function* filterSaga() {
    yield all([
        fork(watchFetchRoomOptionsRequest),
        fork(watchFetchTopicOptionsRequest),
        fork(watchFetchChapterOptionsRequest),
    ]);
}

export default filterSaga;
