import React from 'react';
import { groupedGradeOptions } from './data';
import './filter.css';

type Props = { selectedOption: any; handler: Function }

type GradeOption = { value: string; label: string; };

type GroupedGradeOption = { label: string; options: GradeOption[]; };



const GradeFilter = (props: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value ? JSON.parse(e.target.value) : null;
        props.handler(selectedValue);
    };

    return (
        <div className="grade-filter-container">
            <select
                value={props.selectedOption ? JSON.stringify(props.selectedOption) : ''}
                onChange={handleChange}
            >
                <option value="" disabled>Select Grade</option>

                {groupedGradeOptions.map((group: GroupedGradeOption, index: number) => (
                    <optgroup label={group.label} key={index}>
                        {group.options.map((option: GradeOption) => (
                            <option key={option.value} value={JSON.stringify(option)}>
                                {option.label}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>

            {props.selectedOption && (
                <button onClick={() => props.handler(null)}>Clear</button>
            )}
        </div>
    );
};

export default GradeFilter;
