import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { FilterState } from './filter/types';
import { filterReducer } from './filter/reducer';
import filterSaga from './filter/sagas';

import autherizeReducer from './authorization/reducer';
import autherizeSaga from './authorization/sagas';

import { ToggleState } from './toggle/types';
import { toggleReducer } from './toggle/reducer';

import { ApprovalState } from './approval/types';
import { approvalReducer } from './approval/reducer';
import approvalSaga from './approval/sagas';

import { InviteLinkState } from './invite-link/types';
import { inviteLinkReducer } from './invite-link/reducer';
import inviteLinkSaga from './invite-link/sagas';



export interface ApplicationState {
    filter: FilterState,
    autherize: AutherizeState,
    toggle: ToggleState,
    approval: ApprovalState,
    inviteLink: InviteLinkState,
}



export const createRootReducer = () =>
    combineReducers({
        filter: filterReducer,
        autherize: autherizeReducer,
        toggle: toggleReducer,
        approval: approvalReducer,
        inviteLink: inviteLinkReducer,
    });



export function* rootSaga() {
    yield all(
        [
            fork(autherizeSaga),
            fork(filterSaga),
            fork(approvalSaga),
            fork(inviteLinkSaga),
        ],
    );
}
