export const elementaryOptions = [
    { value: 'E4', label: 'ป.4' },
    { value: 'E5', label: 'ป.5' },
    { value: 'E6', label: 'ป.6' },
];

export const juniorOptions = [
    { value: 'J1', label: 'ม.1' },
    { value: 'J2', label: 'ม.2' },
    { value: 'J3', label: 'ม.3' },
];

export const groupedGradeOptions = [
    {
        label: 'ประถม',
        options: elementaryOptions,
    },
    {
        label: 'มัธยมต้น',
        options: juniorOptions,
    }
];

export const subjectOptions = [
    { value: 'EN', label: 'ภาษาอังกฤษ' },
    { value: 'TH', label: 'ภาษาไทย' },
    { value: 'MA', label: 'คณิตศาสตร์' },
    { value: 'SI', label: 'วิทยาศาสตร์' }
];
