import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer, rootSaga } from './store';
import * as serviceWorker from './serviceWorker';
import FirebaseProvider from './firebase/Firebase';
import App from './App';
import './index.css';



const sagaMiddleware = createSagaMiddleware();
const store = createStore(createRootReducer(), applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);



ReactDOM.render(
    <Provider store={store}>
        <FirebaseProvider>
            <App />
        </FirebaseProvider>
    </Provider>
    , document.getElementById('root'));



serviceWorker.unregister();
