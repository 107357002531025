import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { InviteLinkActionTypes } from './types';
import { fetchInviteTokenSuccess, fetchInviteTokenError } from './actions';
import { callApi } from '../../utils/api';
import { ApplicationState } from '..';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const getUserProfile = (state: ApplicationState) => state.autherize.data;

function* handleFetchInviteToken(): SagaIterator {
    try {
        const { school_id, access_token } = yield select(getUserProfile);

        const res = yield call(callApi, 'get', `${API_ENDPOINT}/v1/school/${school_id}/invite-token`, access_token);

        yield put(res?.error ? fetchInviteTokenError(res.error) : fetchInviteTokenSuccess(res.token));
    } catch (err) {
        yield put(fetchInviteTokenError(err instanceof Error ? err.stack ?? 'An unknown error occurred.' : 'An unknown error occurred.'));
    }
}

function* watchFetchInviteTokenRequest() {
    yield takeEvery(InviteLinkActionTypes.FETCH_INVITE_TOKEN_REQUEST, handleFetchInviteToken);
}

function* inviteLinkSaga() {
    yield all([fork(watchFetchInviteTokenRequest)]);
}

export default inviteLinkSaga;
