import {ApprovalState, ApprovalActionTypes} from './types';

export const initialState: ApprovalState = {
  pendingList: {
    loading: false,
    data: null,
    error: null,
  },
  approvedList: {
    loading: false,
    data: null,
    error: null,
  },
  limitApprovedList: 10,
  totalApprovedList: 0,
};

const reducer = (state: ApprovalState = initialState, action: any) => {
  switch (action.type) {
    case ApprovalActionTypes.FETCH_PENDING_LIST_REQUEST: {
      return {...state, pendingList: {
        loading: true,
        data: state.pendingList.data,
        error: null,
      }};
    }
    case ApprovalActionTypes.FETCH_PENDING_LIST_SUCCESS: {
      return {...state, pendingList: {
        loading: false,
        data: action.payload,
        error: null,
      }};
    }
    case ApprovalActionTypes.FETCH_PENDING_LIST_ERROR: {
      return {...state, pendingList: {
        loading: false,
        data: state.pendingList.data,
        error: action.payload,
      }};
    }
    case ApprovalActionTypes.FETCH_APPROVED_LIST_REQUEST: {
      return {...state, approvedList: {
        loading: true,
        data: state.approvedList.data,
        error: null,
      }};
    }
    case ApprovalActionTypes.FETCH_APPROVED_LIST_SUCCESS: {
      return {...state, approvedList: {
        loading: false,
        data: action.payload,
        error: null,
      }};
    }
    case ApprovalActionTypes.FETCH_APPROVED_LIST_ERROR: {
      return {...state, approvedList: {
        loading: false,
        data: state.approvedList.data,
        error: action.payload,
      }};
    }
    case ApprovalActionTypes.INCREASE_LIMIT_APPROVED_LIST: {
      return {...state, limitApprovedList: state.limitApprovedList + 10};
    }
    case ApprovalActionTypes.SET_TOTAL_APPROVED_LIST: {
      return {...state, totalApprovedList: action.payload};
    }
    default: {
      return state;
    }
  }
};

export {reducer as approvalReducer};
