import {action} from 'typesafe-actions';
import {ApprovalActionTypes, StudentInfo} from './types';

export const fetchPendingListRequest = () => action(ApprovalActionTypes.FETCH_PENDING_LIST_REQUEST);
export const fetchPendingListSuccess = (pendingList: StudentInfo[]) => action(ApprovalActionTypes.FETCH_PENDING_LIST_SUCCESS, pendingList);
export const fetchPendingListError = (message: string) => action(ApprovalActionTypes.FETCH_PENDING_LIST_ERROR, message);

export const fetchApprovedListRequest = () => action(ApprovalActionTypes.FETCH_APPROVED_LIST_REQUEST);
export const fetchApprovedListSuccess = (approvedList: StudentInfo[]) => action(ApprovalActionTypes.FETCH_APPROVED_LIST_SUCCESS, approvedList);
export const fetchApprovedListError = (message: string) => action(ApprovalActionTypes.FETCH_APPROVED_LIST_ERROR, message);

export const approveStudentRequest = (student: StudentInfo) => action(ApprovalActionTypes.APPROVE_STUDENT_REQUEST, student);
export const approveStudentSuccess = () => action(ApprovalActionTypes.APPROVE_STUDENT_SUCCESS);
export const approveStudentError = (message: string) => action(ApprovalActionTypes.APPROVE_STUDENT_ERROR, message);

export const deleteStudentRequest = (student: StudentInfo) => action(ApprovalActionTypes.DELETE_STUDENT_REQUEST, student);
export const deleteStudentSuccess = () => action(ApprovalActionTypes.DELETE_STUDENT_SUCCESS);
export const deleteStudentError = (message: string) => action(ApprovalActionTypes.DELETE_STUDENT_ERROR, message);

export const increaseLimitApprovedList = () => (action(ApprovalActionTypes.INCREASE_LIMIT_APPROVED_LIST));
export const setTotalApprovedList = (total: number) => action(ApprovalActionTypes.SET_TOTAL_APPROVED_LIST, total);
