export const login = (username: String, password: String) : ACTION_FETCH_LOGIN_REQUEST => ({
  type: '@@autherize/FETCH_LOGIN_REQUEST',
  username,
  password,
});

export const loginError = (err: any) : ACTION_FETCH_LOGIN_ERROR => ({
  type: '@@autherize/FETCH_LOGIN_ERROR',
  error: err,
});

export const loginSuccess = (data: any) : ACTION_FETCH_LOGIN_SUCCESS => ({
  type: '@@autherize/FETCH_LOGIN_SUCCESS',
  data,
});

export const clearAccessData = () : ACTION_CLEAR_ACCESS_DATA => ({
  type: '@@autherize/CLEAR_ACCESS_DATA',
});

