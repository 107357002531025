import {action} from 'typesafe-actions';
import {FilterActionTypes, Option} from './types';

// Student Filter
export const setGradeStudentFilter = (grade: Option | null) => action(FilterActionTypes.SET_GRADE_STUDENT_FILTER, grade);
export const setRoomStudentFilter = (room: Option | null) => action(FilterActionTypes.SET_ROOM_STUDENT_FILTER, room);
// Video Filter
export const setGradeVideoFilter = (grade: Option | null) => action(FilterActionTypes.SET_GRADE_VIDEO_FILTER, grade);
export const setSubjectVideoFilter = (subject: Option | null) => action(FilterActionTypes.SET_SUBJECT_VIDEO_FILTER, subject);
export const setTopicVideoFilter = (topic: Option | null) => action(FilterActionTypes.SET_TOPIC_VIDEO_FILTER, topic);
export const setChapterVideoFilter = (chapter: Option | null) => action(FilterActionTypes.SET_CHAPTER_VIDEO_FILTER, chapter);
// Room options of student filter
export const fetchRoomOptionsRequest = () => action(FilterActionTypes.FETCH_ROOM_OPTIONS_REQUEST);
export const fetchRoomOptionsSuccess = (rooms: Option[]) => action(FilterActionTypes.FETCH_ROOM_OPTIONS_SUCCESS, rooms);
export const fetchRoomOptionsError = (message: string) => action(FilterActionTypes.FETCH_ROOM_OPTIONS_ERROR, message);
// Topic options of video filter
export const fetchTopicOptionsRequest = () => action(FilterActionTypes.FETCH_TOPIC_OPTIONS_REQUEST);
export const fetchTopicOptionsSuccess = (topics: Option[]) => action(FilterActionTypes.FETCH_TOPIC_OPTIONS_SUCCESS, topics);
export const fetchTopicOptionsError = (message: string) => action(FilterActionTypes.FETCH_TOPIC_OPTIONS_ERROR, message);
// Chapter options of video filter
export const fetchChapterOptionsRequest = () => action(FilterActionTypes.FETCH_CHAPTER_OPTIONS_REQUEST);
export const fetchChapterOptionsSuccess = (chapters: Option[]) => action(FilterActionTypes.FETCH_CHAPTER_OPTIONS_SUCCESS, chapters);
export const fetchChapterOptionsError = (message: string) => action(FilterActionTypes.FETCH_CHAPTER_OPTIONS_ERROR, message);
