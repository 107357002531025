import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { ApprovalActionTypes } from './types';
import {
    fetchPendingListRequest,
    fetchPendingListSuccess,
    fetchPendingListError,
    fetchApprovedListRequest,
    fetchApprovedListSuccess,
    fetchApprovedListError,
    approveStudentSuccess,
    approveStudentError,
    deleteStudentSuccess,
    deleteStudentError,
    setTotalApprovedList,
} from './actions';
import { fetchRoomOptionsRequest } from '../filter/actions';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../index';
import { FilterActionTypes, StudentFilter } from '../filter/types';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const getUserProfile = (state: ApplicationState) => state.autherize.data;
const getStudentFilter = (state: ApplicationState) => state.filter.studentFilter;
const getLimitOfApprovedList = (state: ApplicationState) => state.approval.limitApprovedList;



function* handleFetchPendingList(): SagaIterator {
    try {
        const userProfile = yield select(getUserProfile);

        const path = `/v1/approval/pending-list?school_id=${userProfile.school_id}`;

        const res = yield call(callApi, 'get', API_ENDPOINT + path, null);
        if (res.error) {
            yield put(fetchPendingListError(res.error));
        } else {
            yield put(fetchPendingListSuccess(res.data.pending_list));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(fetchPendingListError(err.stack));
        } else {
            yield put(fetchPendingListError('An unknown error occured.'));
        }
    }
}



function* handleFetchApprovedList(): SagaIterator {
    try {
        const userProfile = yield select(getUserProfile);
        const studentFilter: StudentFilter = yield select(getStudentFilter);
        const limit = yield select(getLimitOfApprovedList);

        const grade = studentFilter.grade ? studentFilter.grade.value : '';
        const room = studentFilter.room ? studentFilter.room.value : '';

        const path = `/v1/approval/approved-list?school_id=${userProfile.school_id}&grade=${grade}&room_id=${room}&limit=${limit}`;

        const res = yield call(callApi, 'get', API_ENDPOINT + path, null);
        if (res.error) {
            yield put(fetchApprovedListError(res.error));
        } else {
            yield put(fetchApprovedListSuccess(res.data.approved_list));
            yield put(setTotalApprovedList(res.total_approved_list));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(fetchApprovedListError(err.stack));
        } else {
            yield put(fetchApprovedListError('An unknown error occured.'));
        }
    }
}



function* handleApproveStudent(action: any): SagaIterator {
    try {
        const { access_token } = yield select(getUserProfile);
        const path = `/v1/school/${action.payload.school_id}/approve`;

        const res = yield call(callApi, 'put', API_ENDPOINT + path, access_token, {
            room_id: action.payload.room_id,
            user_id: action.payload.user_id,
        });

        if (res.error) {
            yield put(approveStudentError(res.error));
        } else {
            yield put(approveStudentSuccess());
            yield all([
                put(fetchPendingListRequest()),
                put(fetchApprovedListRequest()),
                put(fetchRoomOptionsRequest()),
            ]);
        }
    } catch (err) {
        const errorMsg = err instanceof Error && err.stack ? err.stack : 'An unknown error occurred.';
        yield put(approveStudentError(errorMsg));
    }
}



function* handleDeleteStudent(action: any): SagaIterator {
    try {
        const userProfile = yield select(getUserProfile);

        const path = `/v1/school/delete-user`;

        const res = yield call(callApi, 'delete', API_ENDPOINT + path, userProfile.access_token, {
            user_id: action.payload.id,
        });
        if (res.error) {
            yield put(deleteStudentError(res.error));
        } else {
            yield put(deleteStudentSuccess());
            yield put(fetchPendingListRequest());
            yield put(fetchApprovedListRequest());
            yield put(fetchRoomOptionsRequest());
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(deleteStudentError(err.stack));
        } else {
            yield put(deleteStudentError('An unknown error occured.'));
        }
    }
}



function* watchFetchPendingListRequest() {
    yield takeEvery(ApprovalActionTypes.FETCH_PENDING_LIST_REQUEST, handleFetchPendingList);
}



function* watchFetchApprovedListRequest() {
    yield takeEvery(ApprovalActionTypes.FETCH_APPROVED_LIST_REQUEST, handleFetchApprovedList);
    yield takeEvery(ApprovalActionTypes.INCREASE_LIMIT_APPROVED_LIST, handleFetchApprovedList);
    yield takeEvery(FilterActionTypes.SET_GRADE_STUDENT_FILTER, handleFetchApprovedList);
    yield takeEvery(FilterActionTypes.SET_ROOM_STUDENT_FILTER, handleFetchApprovedList);
}



function* watchApproveStudentRequest() {
    yield takeEvery(ApprovalActionTypes.APPROVE_STUDENT_REQUEST, handleApproveStudent);
}



function* watchDeleteStudentRequest() {
    yield takeEvery(ApprovalActionTypes.DELETE_STUDENT_REQUEST, handleDeleteStudent);
}



function* approvalSaga() {
    yield all([
        fork(watchFetchPendingListRequest),
        fork(watchFetchApprovedListRequest),
        fork(watchApproveStudentRequest),
        fork(watchDeleteStudentRequest),
    ]);
}

export default approvalSaga;
