export interface StudentFilter {
  grade: Option | null
  room: Option | null
}

export interface VideoFilter {
  grade: Option | null
  subject: Option | null
  topic: Option | null
  chapter: Option | null
}

export interface Option {
  value: string
  label: string
  object?: any
}

export interface RoomOptions {
  loading: boolean,
  data: Option[],
  error: string | null,
}

export interface TopicOptions {
  loading: boolean,
  data: Option[],
  error: string | null,
}

export interface ChapterOptions {
  loading: boolean,
  data: Option[],
  error: string | null,
}


export interface Room {
  id: string,
  grade: string,
  school_id: string,
  name: string,
}

export interface Topic {
  grade: string,
  subject_id: string,
  topic_id: string,
  display_order: string,
  description: string,
  description_th: string,
  teacher_image_url: string,
  enabled: string,
}

export interface Chapter {
  grade: string,
  subject_id: string,
  topic_id: string,
  chapter_id: string,
  display_order: string,
  description: string,
  description_th: string,
  has_test: string,
  enabled: string,
  is_free: string,
}

export enum FilterActionTypes {
  // Student filter
  SET_GRADE_STUDENT_FILTER = '@@filter/SET_GRADE_STUDENT_FILTER',
  SET_ROOM_STUDENT_FILTER = '@@filter/SET_ROOM_STUDENT_FILTER',
  // Video filter
  SET_GRADE_VIDEO_FILTER = '@@filter/SET_GRADE_VIDEO_FILTER',
  SET_SUBJECT_VIDEO_FILTER = '@@filter/SET_SUBJECT_VIDEO_FILTER',
  SET_TOPIC_VIDEO_FILTER = '@@filter/SET_TOPIC_VIDEO_FILTER',
  SET_CHAPTER_VIDEO_FILTER = '@@filter/SET_CHAPTER_VIDEO_FILTER',
  // Room options of student filter
  FETCH_ROOM_OPTIONS_REQUEST = '@@filter/FETCH_ROOM_OPTIONS_REQUEST',
  FETCH_ROOM_OPTIONS_SUCCESS = '@@filter/FETCH_ROOM_OPTIONS_SUCCESS',
  FETCH_ROOM_OPTIONS_ERROR = '@@filter/FETCH_ROOM_OPTIONS_ERROR',
  // Topic options of video filter
  FETCH_TOPIC_OPTIONS_REQUEST = '@@filter/FETCH_TOPIC_OPTIONS_REQUEST',
  FETCH_TOPIC_OPTIONS_SUCCESS = '@@filter/FETCH_TOPIC_OPTIONS_SUCCESS',
  FETCH_TOPIC_OPTIONS_ERROR = '@@filter/FETCH_TOPIC_OPTIONS_ERROR',
  // Chapter options of video filter
  FETCH_CHAPTER_OPTIONS_REQUEST = '@@filter/FETCH_CHAPTER_OPTIONS_REQUEST',
  FETCH_CHAPTER_OPTIONS_SUCCESS = '@@filter/FETCH_CHAPTER_OPTIONS_SUCCESS',
  FETCH_CHAPTER_OPTIONS_ERROR = '@@filter/FETCH_CHAPTER_OPTIONS_ERROR',
}

export interface FilterState {
  readonly studentFilter: StudentFilter
  readonly videoFilter: VideoFilter
  readonly roomOptions: RoomOptions
  readonly topicOptions: TopicOptions
  readonly chapterOptions: ChapterOptions
}
