export enum InviteLinkActionTypes {
  FETCH_INVITE_TOKEN_REQUEST = '@@invite-link/FETCH_INVITE_TOKEN_REQUEST',
  FETCH_INVITE_TOKEN_SUCCESS = '@@invite-link/FETCH_INVITE_TOKEN_SUCCESS',
  FETCH_INVITE_TOKEN_ERROR = '@@invite-link/FETCH_INVITE_TOKEN_ERROR',
}

export interface InviteLinkState {
  readonly loading: boolean
  readonly token: string
  readonly error: string | null
}
