import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GradeFilter from '../../components/filter/grade';
import { ApplicationState } from '../../store';
import { Option } from '../../store/filter/types';
import { setGradeStudentFilter, setRoomStudentFilter, fetchRoomOptionsRequest } from '../../store/filter/actions';
import './filter.css';
import filterIcon from '../../images/icons/filter.svg';



const StudentFilter = () => {
    const dispatch = useDispatch();
    const studentFilter = useSelector((state: ApplicationState) => state.filter.studentFilter);



    const handleGradeChange = (e: Option) => {
        dispatch(setRoomStudentFilter(null));
        dispatch(setGradeStudentFilter(e));
    };



    useEffect(() => {
        dispatch(fetchRoomOptionsRequest());
        return () => {
            dispatch(setGradeStudentFilter(null));
            dispatch(setRoomStudentFilter(null));
        };
    }, [dispatch]);



    return (
        <div className="filter-body">
            <div className='filter-title-container'>
                <img className='mr-2' src={filterIcon} alt='' />
                Filter
            </div>
            <GradeFilter selectedOption={studentFilter.grade} handler={handleGradeChange} />
        </div>
    );
};

export default StudentFilter;
