import React, { createContext } from 'react';
import firebaseConfig from './FirebaseConfig';
import app from 'firebase/app';
import 'firebase/database';

const FirebaseContext = createContext<FirebaseContextType>({});
export { FirebaseContext };

const Firebase = (props: { children: any }) => {
    let firebase: FirebaseContextType = {};

    if (!app.apps.length) {
        app.initializeApp(firebaseConfig);
        firebase = {
            app: app,
            database: app.database(),
        };
    }

    return (
        <FirebaseContext.Provider value={firebase}>
            {props.children}
        </FirebaseContext.Provider>
    );
};

export default Firebase;
