import { ToggleState, ToggleActionTypes } from './types';

export const initialState: ToggleState = {
    toggle: 'dashboard',
    playlists: [],
};

const reducer = (state: ToggleState = initialState, action: any) => {
    switch (action.type) {
        case ToggleActionTypes.SET_TOGGLE: {
            localStorage.setItem('TOGGLE', JSON.stringify(action.payload));
            return { ...state, toggle: action.payload };
        }
        case ToggleActionTypes.SET_PLAYLISTS: {
            return { ...state, playlists: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as toggleReducer };
