import React, { useState, useEffect } from 'react';
import './login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/authorization/actions';
import { ApplicationState } from '../../store';
import BarLoader from 'react-spinners/BarLoader';
import { useHistory } from 'react-router';



const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');



    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(login(username, password));
    };



    useEffect(() => {
        if (loginState.data) history.push('/');
    });



    return <form onSubmit={handleSubmit}>
        <div className="login-container">
            <BarLoader
                color='#0b7aff'
                width='100%'
                loading={loginState.loading ? true : false} />
            <div className="login-content">
                <div className="login-title">
                    Login
                </div>
                <div className="login-container-input" style={{ marginTop: '20px' }}>
                    <div className="login-title-input">E-mail Address</div>
                    <input type="email" placeholder="Example@nockacademy.com" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                </div>
                <div className="login-container-input" style={{ marginTop: '15px' }}>
                    <div className="login-title-input">Password</div>
                    <input type="password" placeholder="••••••••••••" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                </div>
                <button type="submit" className="login-button-submit" style={{ marginTop: '20px' }}>Login</button>
                <div className="text-danger" style={{ marginTop: '10px', textAlign: 'center' }}>{loginState.error?.message}</div>
            </div>
        </div>
    </form>;
};

export default Login;
