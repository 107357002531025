import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from './pages/main';

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={MainPage} />
                <Route component={() => <div>Not Found</div>} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;