import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import { FirebaseContext } from '../../firebase/Firebase';
import StudentFilter from '../../containers/filter/student-filter';
import ModalHomework from './homework-modal';
import ModalLiveQuiz from './livestream-modal';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './table.css';
import './livestream-table.css'
import userImage from '../../images/user/user-image.png';



const LivestreamTable = (props: { liveId: number }) => {

    const { database: firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [attendanceSet, setAttendanceSet] = useState<Set<string>>(new Set());
    const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});
    const [isHomeworkModalOpen, setIsHomeworkModalOpen] = useState(false);
    const [isLivestreamModalOpen, setIsLivestreamModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [loading, setLoading] = useState<boolean>(true);
    const [modalFullname, setModalFullName] = useState<string>('');
    const [modalUserId, setModalUserId] = useState<string>('');

    const sortedList = approvedList.data
        ? [...approvedList.data]
            .filter((user) => user.fullname.toLowerCase().includes(searchQuery.toLowerCase()))
            .sort((a, b) => a.fullname.localeCompare(b.fullname))
        : [];

    const gradeMap: { [key: string]: string } = { 'E4': 'ป.4', 'E5': 'ป.5', 'E6': 'ป.6', 'J1': 'ม.1', 'J2': 'ม.2', 'J3': 'ม.3' };

    if (!firebase) throw new Error('Database is not available');



    const mapGrade = (grade: string): string => gradeMap[grade] || 'Unknown';

    const mapIsPremium = (is_premium: number): string => is_premium === 1 ? 'Premium' : 'Free';

    const formatDate = (expireTime: moment.MomentInput) => expireTime ? moment(expireTime).format('D MMM, YY') : '—';

    const handleCheckboxChange = async (userId: string, currentPremiumPlus: number) => {
        const newPremiumPlus = currentPremiumPlus === 1 ? 0 : 1;
        setCheckboxStates(prevStates => ({ ...prevStates, [userId]: newPremiumPlus === 1 }));
        try {
            const { token } = loginState.data;
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/toggle-premium-plus`, token, { userId, currentPremiumPlus });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error toggling Premium Plus:', error);
        }
    };

    const fetchMessageListAll = async (ref: firebase.database.Reference) => {
        try {
            setLoading(true);
            const snapshot = await ref.orderByKey().once('value');
            const data = snapshot.val() || {};
            const attendanceSet = new Set<string>();

            Object.entries(data).forEach(([, vals]) => {
                const value = vals as { Id: number; };
                const userId = value.Id.toString();
                attendanceSet.add(userId);
            });
            setAttendanceSet(attendanceSet);

        } catch (error) {
            console.error("Error fetching message list:", error);
        } finally {
            setLoading(false);
        }
    };

    const openLivestreamModal = (fullname: string, userId: string) => {
        setModalFullName(fullname); setModalUserId(userId); setIsLivestreamModalOpen(true);
    };

    const closeLivestreamModal = () => {
        setIsLivestreamModalOpen(false); setModalFullName(''); setModalUserId('');
    };

    const openHomeworkModal = (fullname: string, userId: string) => {
        setModalFullName(fullname); setModalUserId(userId); setIsHomeworkModalOpen(true);
    };

    const closeHomeworkModal = () => {
        setIsHomeworkModalOpen(false); setModalFullName(''); setModalUserId('');
    };



    useEffect(() => {
        fetchMessageListAll(firebase.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${props.liveId}/`));
    }, [props.liveId]);

    useEffect(() => {
        dispatch(fetchApprovedListRequest());
    }, [dispatch]);



    return (
        <>
            <div className="filter-container">
                <input
                    type="text" placeholder="Search by name" className="livestream-table-search-box"
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                />
                <StudentFilter />
            </div>

            <table className="table-container">
                <thead>
                    <tr>
                        <th className="livestream-table-name">Name</th>
                        <th className="livestream-table-centered">Grade</th>
                        <th className="livestream-table-centered">Type</th>
                        <th className="livestream-table-centered">Exp. Date</th>
                        <th className="livestream-table-centered">Premium Plus</th>
                        <th className="livestream-table-centered">Attendance</th>
                        <th className="livestream-table-centered">Live Quiz</th>
                        <th className="livestream-table-centered">Homework</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => (
                            <tr key={user.id}>

                                <td>
                                    <img className='table-profile-image' src={user.lowPhotoUrl || userImage} alt='' />
                                    {user.fullname}
                                </td>

                                <td className="livestream-table-centered">
                                    {mapGrade(user.grade)}
                                </td>

                                <td className="livestream-table-centered">
                                    {mapIsPremium(user.is_premium)}
                                </td>

                                <td className="livestream-table-centered">
                                    {formatDate(user.expire_time)}
                                </td>

                                <td className="livestream-table-centered">
                                    <input
                                        type="checkbox"
                                        checked={checkboxStates[user.id] !== undefined ? checkboxStates[user.id] : user.premium_plus === 1}
                                        onChange={() => handleCheckboxChange(user.id, user.premium_plus)}
                                    />
                                </td>

                                <td className={`livestream-table-centered attendance-${attendanceSet.has(user.id.toString()) ? 'present' : 'absent'}`}>
                                    {loading ? <PulseLoader color="#0b7aff" /> : (attendanceSet.has(user.id.toString()) ? 'Present' : 'Absent')}
                                </td>

                                <td className="livestream-table-centered">
                                    <button className="live-modal-view-button" onClick={() => openLivestreamModal(user.fullname, user.id.toString())}>
                                        View
                                    </button>
                                </td>

                                <td className="livestream-table-centered">
                                    <button className="live-modal-view-button" onClick={() => openHomeworkModal(user.fullname, user.id.toString())}>
                                        View
                                    </button>
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={8} className="no-playlists">No Users Available</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <ModalLiveQuiz
                isOpen={isLivestreamModalOpen} onClose={closeLivestreamModal}
                fullname={modalFullname} userId={modalUserId} liveId={props.liveId}
            />

            <ModalHomework
                isOpen={isHomeworkModalOpen} onClose={closeHomeworkModal}
                fullname={modalFullname} userId={modalUserId} liveId={props.liveId}
            />

        </>
    );
};

export default LivestreamTable;
