export const callApi = async (
    method: string, url: string, token: string | null, data?: any
): Promise<any> => {
    try {
        const headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` })
        };

        const response = await fetch(url, {
            method, headers, body: data ? JSON.stringify(data) : undefined,
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error ${response.status}: ${errorText}`);
        }

        return response.json();

    } catch (error) {
        console.error('API call failed:', error);
        throw error;
    }
};
