import {InviteLinkState, InviteLinkActionTypes} from './types';

export const initialState: InviteLinkState = {
  loading: false,
  token: '',
  error: null,
};

const reducer = (state: InviteLinkState = initialState, action: any) => {
  switch (action.type) {
    case InviteLinkActionTypes.FETCH_INVITE_TOKEN_REQUEST: {
      return {...state, loading: true};
    }
    case InviteLinkActionTypes.FETCH_INVITE_TOKEN_SUCCESS: {
      return {...state, loading: false, token: action.payload};
    }
    case InviteLinkActionTypes.FETCH_INVITE_TOKEN_ERROR: {
      return {...state, loading: false, error: action.payload};
    }
    default: {
      return state;
    }
  }
};

export {reducer as inviteLinkReducer};
