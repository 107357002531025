import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { loginError, loginSuccess } from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

function* handleFetch(action: ACTION_FETCH_LOGIN_REQUEST): SagaIterator {
    try {
        const res = yield call(callApi, 'post', `${API_ENDPOINT}/v1/user/sign-in/teacher`, null,
            { username: action.username, password: action.password }
        );

        if (res.error) {
            console.log(res.error);
            yield put(loginError(res.error));
        } else {
            yield put(loginSuccess(res));
        }
    } catch (err) {
        console.log(err);
        const errorMessage = err instanceof Error ? err.stack || err.message || 'An error occurred.' : 'An unknown error occurred.';
        yield put(loginError(errorMessage));
    }
}

function* watchFetchRequest() {
    yield takeEvery<ACTION_FETCH_LOGIN_REQUEST>('@@autherize/FETCH_LOGIN_REQUEST', handleFetch);
}

function* autherizeSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default autherizeSaga;
