const initialState: AutherizeState = {
  loading: false,
  data: null,
  error: null,
};
const st = localStorage.getItem('ACCESS_DATA');
if (st) {
  initialState.data = JSON.parse(st);
}

const reducer = (state: AutherizeState = initialState, action: AutherizeActionTypes) => {
  switch (action.type) {
    case '@@autherize/FETCH_LOGIN_REQUEST':
      return {state, loading: true};
    case '@@autherize/FETCH_LOGIN_ERROR':
      return {state, loading: false, error: action.error};
    case '@@autherize/FETCH_LOGIN_SUCCESS':
      localStorage.setItem('ACCESS_DATA', JSON.stringify(action.data.data));
      return {state, loading: false, error: null, data: action.data.data};
    case '@@autherize/CLEAR_ACCESS_DATA':
      localStorage.clear();
      return {state, loading: false, error: null, data: null};
    default:
      return state;
  }
};

export default reducer;
